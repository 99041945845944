'use strict';

const form = document.getElementById('contact')

if (form) {
    const submitButton = form.querySelector('button[type="submit"]')

    window.interactsWithCaptcha = _ => {
        try {
            grecaptcha.render('g-recaptcha', {
                sitekey: document.getElementById('g-recaptcha').dataset.sitekey,
                callback: (...argscallback) => argscallback.length ? submitButton.classList.remove('d-none') : submitButton.classList.add('d-block'),
                'expired-callback': _ => {
                    submitButton.classList.add('d-none')

                    grecaptcha.reset()
                }
            })
        } catch (error) {}
    }

    form.addEventListener('submit', e => {
        e.preventDefault()
        e.stopPropagation()

        let originalSubmitButton = {
            html: $(submitButton).html(),
            classes: $(submitButton).attr('class')
        }

        $(submitButton).attr({
            disabled: true,
            class: 'btn btn-success btn-lg py-2 px-4 disabled'
        }).html(' <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Enviando...')

        $.ajax({
            url: form.action,
            type: 'POST',
            data: $(form).serialize(),
        }).done(response => {
            $('#form-response')
            .fadeIn()
            .removeClass('d-none')
            .find('.alert')
            .addClass('alert-success')
            .find('p')
            .html(response)

            form.reset()

            grecaptcha.reset()
        }).fail(error => {
            $('#form-response')
            .fadeIn()
            .removeClass('d-none')
            .find('.alert')
            .addClass('alert-danger')
            .find('p')
            .html(error.responseText)

            grecaptcha.reset()
        }).always(_ => {
            $(submitButton).attr({
                disabled: false,
                class: originalSubmitButton.classes.concat(' d-none')
            }).html(originalSubmitButton.html)

            window.setTimeout(_ => $('#form-response').fadeOut(), 5000)
        })
    })
}